import notify from "devextreme/ui/notify";
import { confirm, alert } from "devextreme/ui/dialog";
import DataSource from "devextreme/data/data_source";
import ApiHelper from "./api-helper";
import CustomStore from "devextreme/data/custom_store";
import DaftarAkses from "../akses";
import { formatNumber as dxFormatNumber } from "devextreme/localization";
import { DateTime } from "luxon";

class Helper {
  static toast(msg, type = "info") {
    notify(msg, type);
  }

  static info(msgHtml, title = "Informasi") {
    alert(msgHtml, title);
  }

  static konfir(msgHtml = "<i>Apakah Anda yakin?</i>", title = "Konfirmasi") {
    return confirm(msgHtml, title);
  }

  static createGridDataSource(path) {
    return new DataSource(this.createCustomStoreConfig(path));
  }

  static createCustomStoreConfig(path) {
    return {
      key: "id",
      load(loadOptions) {
        return new Promise((resolve, reject) => {
          ApiHelper.post(path, loadOptions)
            .then((data) => {
              resolve(data);
            })
            .catch((e) => reject(e));
        });
      },
      byKey(key) {
        return new Promise((resolve, reject) => {
          ApiHelper.post(path, { id: key })
            .then((data) => {
              resolve(data);
            })
            .catch((e) => reject(e));
        });
      },
    };
  }

  static createLookupDataSource(path) {
    let store = new CustomStore(this.createCustomStoreConfig(path));
    return new DataSource({
      store,
      paginate: true,
      pageSize: 20,
    });
  }

  static readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  static getDaftarAkses() {
    let ret = [];
    for (var x of DaftarAkses) {
      ret.push({
        id: x.id,
        nama: x.nama,
        lihat: false,
        baru: false,
        update: false,
        hapus: false,
        import: false,
        export: false,
        upload: false,
      });
    }
    return ret;
  }

  static setDaftarAkses(daftar_akses, konfigurasi) {
    for (var k of konfigurasi) {
      let temp = k.split(":");
      let id = temp[0];
      let akses = temp[1];
      let itm = daftar_akses.find((x) => x.id === id);
      if (itm) {
        itm[akses] = true;
      }
    }
  }

  static formatNumber(n) {
    return dxFormatNumber(n, '#,###.##')
  }

  static formatDate(d) {
    let t = null
    if(d instanceof Date) {
      t = DateTime.fromJSDate(d)
    } else {
      d = d.replace(/\//g, '-')
      t = DateTime.fromFormat(d, 'yyyy-MM-dd HH:mm:ss')
    }
    return t.toISODate()
  }
}

export default Helper;
