function createItem(id, nama, akses = []) {
  return {
    id,
    nama,
    akses,
  };
}

export default [
  createItem("pelanggan", "Master Pelanggan", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("supplier", "Master Supplier", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("akun", "Master Akun", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("aset", "Master Aset", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("pengguna", "Master Pengguna", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("jabatan", "Master Jabatan", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("jual", "Penjualan", ["lihat", "baru", "update", "hapus", "import", "export", "upload"]),
  createItem("beli", "Pembelian", ["lihat", "baru", "update", "hapus", "import", "export", "upload"]),
  createItem("piutang", "Piutang", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("hutang", "Hutang", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("piutang_adj", "Penyesuaian Piutang", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("hutang_adj", "Penyesuaian Hutang", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("biaya", "Biaya", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("penyusutan", "Penyusutan", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("pendapatan", "Pendapatan", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("jurnal", "Jurnal Umum", ["lihat", "baru", "update", "hapus", "export", "upload"]),
  createItem("neraca_saldo", "Master Jabatan", ["lihat", "baru", "update", "hapus", "export"]),
  createItem("neraca", "Neraca", ["lihat"]),
  createItem("laba_rugi", "Laba Rugi", ["lihat"]),
  createItem("daftar_jurnal", "Daftar Jurnal", ["lihat"]),
  createItem("buku_besar", "Buku Besar", ["lihat"]),
  createItem("kartu_hutang", "Kartu Hutang", ["lihat"]),
  createItem("kartu_piutang", "Kartu Piutang", ["lihat"]),
  createItem("setting", "Setting", ["lihat"]),
];
